<template>
	<div>
		<b-container 
      style="max-width:90%;"
    >
      <b-row 
        class="sticky-top"
        style="background-color:white;"
      >
        <b-link 
          :href="pdfData" 
          :download="pdfData + '.pdf'"
        >
          資料ダウンロード
        </b-link>
        <h5 
          style="text-align:right;"
        >
          {{ pdfPage }}/{{ totalPageNum }} 
        </h5>
        <b-col 
          style="text-align:right; "
          class="mb-1"
        >
          <b-button 
            :disabled="pdfPage == 1"
            @click="prevPage"
            variant="primary"
          >
            戻る
          </b-button>
          <b-button
            :disabled="totalPageNum == pdfPage"
            class="ms-2"
            @click="nextPage"
            variant="primary"
          >
            次へ
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <pdf
          :src="pdfURL"
          :page="pdfPage"
          @num-pages="totalPageNum = $event"
        ></pdf>
      </b-row>
		</b-container>
	</div>
</template>

<script>
import pdf from 'vue-pdf'
	export default {
    data() {
			return {
        totalPageNum: 0,
        pdfPage:1,
				pdfData:'',
			}
    },
    mounted() {
			this.showLoader = true
			this.pdfData = this.pdfURL + this.$route.params.materialID
			document.title = this.$route.params.materialName
    },

    methods: {
      //前へを押された場合
			prevPage() {
				if (this.pdfPage <= 1) {
					return
				}
				this.pdfPage = this.pdfPage - 1
			},
      //次へを押された場合
			nextPage() {
				if (this.pdfPage >= this.totalPageNum) {
				return
				}
				this.pdfPage = this.pdfPage + 1
			},
    },
    watch:{
    'pdfData':function() {
      this.pdfURL = pdf.createLoadingTask({
        url: this.pdfData,
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/cmaps/',
        cMapPacked: true,
      });
    }
  }
	}
</script>
